import { useEffect } from 'react';

import { useLessonReport } from 'hooks/api/portfolioService/queries/useLessonReport';

export function FetchLessonReport({
  activeViewMode,
  lessonSessionId,
  setData,
  setDenominator,
  setError,
  setMax,
  studentId,
}) {
  const { data, error, isLoading } = useLessonReport({
    lessonSessionId,
    userId: studentId,
  });

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const score = data?.questionScores?.overallResults.find(
      (score) => score.viewMode === activeViewMode,
    );

    setData(data || '');
    setError(error);
    setDenominator(score?.earned);
    setMax(score?.max);
  }, [
    data,
    error,
    isLoading,
    lessonSessionId,
    setData,
    setDenominator,
    setError,
    setMax,
    studentId,
    activeViewMode,
  ]);

  return null;
}
