import { NavLink, useLocation } from 'react-router-dom';

import {
  Content,
  DemoInfoBar,
  Header,
  InfoBar,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';
import URLS from 'utils/constants/urls';
import { dataTestIds } from 'utils/dataTestIds';
import { useContext } from 'react';
import { UserContext } from 'providers/UserProvider';

export function StudentHeader({
  choseActivePeriod,
  currentPeriod,
  hasActivePeriod,
  onPeriodClick,
}) {
  const t = useFormatMessage();

  const user = useContext(UserContext);
  const { demo, isImpersonating, organization } = user;
  const { pathname } = useLocation();

  const container = (children) => (
    <Content hasPaddingBottom={false}>{children}</Content>
  );

  return (
    <>
      <Header
        choseActivePeriod={choseActivePeriod}
        currentPeriod={currentPeriod}
        onPeriodClick={onPeriodClick}
        organization={organization}
        profileLinks={[
          {
            label: t('header.profile'),
            to: URLS.PROFILE,
            test: dataTestIds.header.userMenu.links.profile,
          },
          {
            label: isImpersonating
              ? t('global.unimpersonate')
              : t('header.logout'),
            to: URLS.LOGOUT,
            test: dataTestIds.header.userMenu.links.logout,
          },
        ]}
        user={user}
      >
        {organization.showCalendar && (
          <NavLink data-test={dataTestIds.header.home} to={URLS.HOME}>
            {t('calendar.title')}
          </NavLink>
        )}

        <NavLink data-test={dataTestIds.header.learn} to={URLS.LEARN}>
          {t('global.LEARN')}
        </NavLink>

        <NavLink data-test={dataTestIds.header.exercise} to={URLS.EXERCISE}>
          {t('global.EXERCISE.plural')}
        </NavLink>

        {organization.language?.toLowerCase() === 'nl' &&
          organization.name.indexOf('B2C') === -1 && (
            <NavLink data-test={dataTestIds.header.hackroom} to={URLS.HACKROOM}>
              {t('global.hack_room')}
            </NavLink>
          )}
      </Header>

      {demo && <DemoInfoBar container={container} path={pathname} />}
      {!hasActivePeriod ? (
        <InfoBar
          className="max-w-screen-md"
          container={container}
          text={t('header.no.active.period.studentAndTeacher')}
        />
      ) : null}
    </>
  );
}
