import { Children, cloneElement, forwardRef, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import useFormatMessage from '../hooks/useFormatMessage';
import { Badge } from './Badge';
import { dataTestIds } from '../utils/constants/dataTestIds';
import { Logo } from './Logo';
import { Avatar } from './Avatar';

export const HIDE_IN_STUDIO = 'hideInStudio';

const DynamicLink = forwardRef(
  ({ children, ref, target, to, ...props }, innerRef) => {
    if (to?.startsWith('http')) {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <a href={to} target={target} {...props}>
          {children}
        </a>
      );
    }
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Link to={to} {...props} ref={innerRef}>
        {children}
      </Link>
    );
  },
);

export function Header({
  children = null,
  choseActivePeriod = false,
  currentPeriod = null,
  icons = [],
  onPeriodClick = () => {},
  organization = {},
  profileLinks,
  user,
}) {
  const t = useFormatMessage();
  const { isCodefever } = organization;

  const hideNavAndOrganization = isCodefever;
  const isCodeCosmosB2C =
    organization.idp === 'CODECOSMOS' &&
    organization.name.indexOf('B2C') !== -1;
  const hidePeriodFunctions = isCodefever || isCodeCosmosB2C;

  console.log(organization);

  return (
    <Disclosure as="nav" className="bg-white border-b w-full">
      {({ open }) => (
        <>
          <div className="max-w-5/6 mx-auto w-full">
            <div className="flex justify-between h-16">
              <div className="flex">
                <Logo
                  organization={organization}
                  showOrganization={!isCodefever && !isCodeCosmosB2C}
                />

                {/* This is the main navigation on desktop */}
                {!hideNavAndOrganization && (
                  <ul className="hidden sm:ml-6 sm:flex sm:space-x-8">
                    {/* Current: "border-accent-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                    {Children.map(children, (child, i) => {
                      // yeah, this is needed, don't remove it
                      if (child) {
                        return (
                          <Disclosure.Button key={`${i + 1}`} as="li">
                            {cloneElement(child, {
                              className: ({ isActive }) =>
                                classNames([
                                  'inline-flex h-full items-center px-1 pt-1 border-b-2 text-sm font-medium',
                                  {
                                    'border-accent-500 text-gray-900': isActive,
                                  },
                                  {
                                    'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700':
                                      !isActive,
                                  },
                                ]),
                            })}
                          </Disclosure.Button>
                        );
                      }

                      return null;
                    })}
                  </ul>
                )}
              </div>
              {/* This is the profile dropdown + the two other icons on desktop */}
              <div className="hidden sm:ml-6 sm:flex sm:items-center gap-1">
                {icons?.map((icon) => (
                  <DynamicLink
                    key={icon.label}
                    className="flex gap-1 bg-white p-1 rounded-full text-gray-500 hover:underline hover:underline-offset-2 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-500"
                    data-test={icon.test}
                    target={icon.target}
                    title={icon.label}
                    to={icon.to}
                  >
                    <span className={icon?.showLabel ? '' : 'sr-only'}>
                      {icon.label}
                    </span>
                    {cloneElement(icon.icon, {
                      'aria-hidden': 'true',
                      className: 'h-6 w-6',
                    })}
                  </DynamicLink>
                ))}
                {/* Profile dropdown */}
                {user && (
                  <Menu as="div" className="ml-3 relative z-20">
                    <div>
                      <Menu.Button
                        className="bg-white rounded-full flex text-sm hover:underline hover:underline-offset-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-500"
                        data-test={dataTestIds.component.header.userMenu}
                      >
                        <span className="sr-only">Open user menu</span>
                        <div className="flex items-center">
                          {user.avatar && user.avatar !== HIDE_IN_STUDIO && (
                            <Avatar
                              avatar={user.avatar}
                              className="mr-1 w-8 h-8"
                            />
                          )}{' '}
                          {user?.firstname}{' '}
                          {user?.isImpersonating ? (
                            <span className="inline-flex items-center rounded-full bg-red-100 px-2 py-0.5 text-xs font-medium text-red-800 ml-2">
                              {t('header.impersonated')}
                            </span>
                          ) : null}
                          {!hidePeriodFunctions &&
                            !choseActivePeriod &&
                            currentPeriod?.name && (
                              <Badge
                                className="mx-4 my-2 w-fit max-w-[calc(100%-2rem)]"
                                warning
                              >
                                <button
                                  className="truncate hover:underline"
                                  onClick={onPeriodClick}
                                  type="button"
                                >
                                  {' '}
                                  {currentPeriod?.name}
                                </button>
                              </Badge>
                            )}
                          <ChevronDownIcon className="w-4 h-4 ml-2" />
                        </div>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="border border-solid border-gray-50 origin-top-right absolute right-0 mt-2 w-48 rounded-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {!hidePeriodFunctions &&
                          currentPeriod &&
                          choseActivePeriod && (
                            <Badge
                              className="mx-4 my-2 w-fit max-w-[calc(100%-2rem)]"
                              warning
                            >
                              <button
                                className="truncate hover:underline"
                                onClick={onPeriodClick}
                                type="button"
                              >
                                {currentPeriod.name}
                              </button>
                            </Badge>
                          )}
                        {profileLinks?.map((link) => (
                          <Menu.Item key={link.label}>
                            {({ active }) => (
                              <DynamicLink
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700',
                                )}
                                data-test={link.test}
                                target={link.target}
                                to={link.to}
                              >
                                {link.label}
                              </DynamicLink>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                )}
              </div>
              {/* This is the open/close menu button on mobile for the main navigation */}
              <div className="-mr-2 flex items-center sm:hidden">
                {/*  /!* Mobile menu button *!/ */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-accent-500">
                  {open ? (
                    <>
                      <span className="sr-only">Close main menu</span>
                      <XMarkIcon aria-hidden="true" className="block h-6 w-6" />
                    </>
                  ) : (
                    <>
                      <span className="sr-only">Open main menu</span>
                      <Bars3Icon aria-hidden="true" className="block h-6 w-6" />
                    </>
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          {/* main navigation menu on mobile */}
          <Disclosure.Panel className="sm:hidden">
            {!hideNavAndOrganization && (
              <ul className="pt-2 pb-3 space-y-1">
                {Children.map(children, (child, i) => {
                  // yeah, this if is needed: don't remove it!
                  if (child) {
                    return (
                      <Disclosure.Button key={`${i + 1}`} as="li">
                        {cloneElement(child, {
                          className: ({ isActive }) =>
                            classNames([
                              ' block pl-3 pr-4 py-2 border-l-4 text-base font-medium',
                              isActive
                                ? 'bg-accent-50 border-accent-500 text-accent-700'
                                : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700',
                            ]),
                        })}
                      </Disclosure.Button>
                    );
                  }

                  return null;
                })}
              </ul>
            )}
            <div className="pt-4 pb-3 border-t border-gray-200">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                  {user?.avatar !== HIDE_IN_STUDIO && (
                    <Avatar avatar={user?.avatar} className="mr-1 w-6 h-6" />
                  )}
                </div>
                <div className="flex-grow">
                  <div className="text-base font-medium text-gray-800">
                    {user?.firstname} {user?.lastname}
                  </div>
                  <div className="text-sm font-medium text-gray-500">
                    {user?.email}
                  </div>
                </div>

                {icons?.map((icon) => (
                  <DynamicLink
                    key={icon.label}
                    className="ml-auto flex-shrink-0 bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-500"
                    data-test={icon.test}
                    target={icon.target}
                    title={icon.label}
                    to={icon.to}
                  >
                    <span className="sr-only">{icon.label}</span>
                    {cloneElement(icon.icon, {
                      className: 'h-6 w-6',
                      'aria-hidden': 'true',
                    })}
                  </DynamicLink>
                ))}
              </div>
              {!isCodefever && (
                <div className="mt-3 space-y-1">
                  {currentPeriod && !hidePeriodFunctions && (
                    <Badge
                      className="mx-4 my-2 w-fit max-w-[calc(100%-2rem)]"
                      warning
                    >
                      <button
                        className="truncate hover:underline"
                        onClick={onPeriodClick}
                        type="button"
                      >
                        {currentPeriod.name}
                      </button>
                    </Badge>
                  )}
                  {profileLinks?.map((link) => (
                    <Disclosure.Button key={link.label} as={Fragment}>
                      {() => (
                        <DynamicLink
                          className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                          target={link.target}
                          to={link.to}
                        >
                          {link.label}
                        </DynamicLink>
                      )}
                    </Disclosure.Button>
                  ))}
                </div>
              )}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
